<template>
  <div class="carousel-containner">
    <ul class="wrap">
      <li class="poster-item" @click="routerPush(bk)"  v-for="(bk,index) in content" :key="index" :style="cssList[index]" v-touch:swipeleft="pre" v-touch:swiperight="next">
        <div class="bkImg p_r">
          <img :src="bk.wallpaperPreviewUrl">
          <!--<a href="javascript:;" @click.stop="showPop(bk.originalWallpaperUrl,bk.name)" class="download_pic p_a">
            <img src="./img/cc_download.png" alt="">
          </a>-->
        </div>
        <div class="bkName"><span>{{bk.name}}</span></div>
        <div class="description" :style="opList[index]">{{synopsis}}</div>
      </li>
    </ul>

    <!--<div class="downLoadPop p_f" v-if="cancelPop">
      <div class="d_pop p_a t_a">
        <a href="javascript:;" @click.stop="downloadByData()" class="t_a">
          <van-icon name="down" class="down_line"/>
          <span>下载高清图片</span>
        </a>
        &lt;!&ndash;<a :href="downLoadUrl" download="download">下载高清图片</a>&ndash;&gt;
        <div class="cur_p" @click="cancelPop = false">取消</div>
      </div>

    </div>-->
  </div>
</template>

<script>
  import img from './img/banner.png'
  export default {
    props: {
      opacity: {
        type: Number,
        default: 1
      },
      scal: {
        type: Number,
        default: 0.95
      },
      width: {
        type:Number,
        default: 8.16
      },
      height: {
        type: Number,
        default: 13.36
      },
      containerWidth: {
        type: Number,
        default: 10
      },
      fontSize: {
        type: Number,
        default: 0.56
      },
      content:{
        type: Array,
        default:[],
      }
    },
    directives:{
      touch:{
        bind: function (el, binding, vnode) {
          var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
          var timeOutEvent = 0;
          var direction = '';
          //滑动处理
          var startX, startY;
          //返回角度
          function GetSlideAngle(dx, dy) {
            return Math.atan2(dy, dx) * 180 / Math.PI;
          }
          //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
          function GetSlideDirection(startX, startY, endX, endY) {
            var dy = startY - endY;
            var dx = endX - startX;
            var result = 0;
            //如果滑动距离太短
            if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
              return result;
            }
            var angle = GetSlideAngle(dx, dy);
            if (angle >= -45 && angle < 45) {
              result = 'swiperight';
            } else if (angle >= 45 && angle < 135) {
              result = 'swipeup';
            } else if (angle >= -135 && angle < -45) {
              result = 'swipedown';
            }
            else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
              result = 'swipeleft';
            }
            return result;
          }
          el.addEventListener('touchstart', function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;
            //判断长按
            timeOutEvent = setTimeout(() =>{
              timeOutEvent = 0 ;
              if(touchType === 'press'){
                binding.value()
              }
            } , 500);
          }, false);
          el.addEventListener('touchmove' , function (ev) {
            clearTimeout(timeOutEvent)
            timeOutEvent = 0;
          });
          el.addEventListener('touchend', function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);
            clearTimeout(timeOutEvent)
            switch (direction) {
              case 0:
                break;
              case 'swipeup':
                if(touchType === 'swipeup'){
                  binding.value()
                }
                break;
              case 'swipedown':
                if(touchType === 'swipedown'){
                  binding.value()
                }
                break;
              case 'swipeleft':
                if(touchType === 'swipeleft'){
                  binding.value()
                }
                break;
              case 'swiperight':
                if(touchType === 'swiperight'){
                  binding.value()
                }
                break;
              default:
            }
          }, false);
        }
      }
    },
    data() {
      return {
        downLoadUrl:'',
        downLoadName:'',
        cancelPop:false,
        num: 0,
        cssList: [],
        opList: [],
        currentIndex: 0,
        index:0,
        synopsis:'',
      }
    },
    created() {
      this.init();
      this.setDefault();
    },
    methods: {
      routerPush(obj){
        let arr={
          areaId:obj.areaId,
          name:obj.name,
          cityCode:obj.cityCode
        }
        this.$router.push(`/cityDetails/?obj=${JSON.stringify(arr)}`)
      },
      downloadByData () {
        let send = JSON.stringify({fileUrl:this.downLoadUrl});
        if(this.$global.modelState){
          window.android.downloadFile(this.downLoadUrl);
        }else {
          this.$iosMethod(function(bridge) {
            bridge.callHandler('downloadFile', send ,function(data) {

            })
          })
        }

       /* let _t = this;
        let image = new Image()
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
        // image.src = img
        console.log(url)
        image.onload =function (){
          let canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, image.width, image.height)
          let ext = image.src.substring(image.src.lastIndexOf('.')+1).toLowerCase()
          let dataURL = canvas.toDataURL('image/' + ext)
          console.log(dataURL)
          // _t.download(dataURL)
        };
        image.onerror = function(err){
          console.log(err)
        }*/
      },

      download (href, name = 'pic') {
        let eleLink = document.createElement('a')
        eleLink.download = name
        eleLink.href = href
        eleLink.click()
        eleLink.remove()
      },
      showPop(url,name){
        this.downLoadUrl = url;
        this.downLoadName = name;
        this.cancelPop = true;
      },
      init() {
        let len=this.content.length;
        if(len%2==0){
          this.content.copyWithin(len-1,0,1);
        }
        this.num=this.content.length;
        this.synopsis=this.content[this.index].synopsis;
      },
      setDefault() {
        let obj={
          zIndex:Math.floor(this.num/2),
          width:this.width+'rem',
          height:this.height+'rem',
          left: (this.containerWidth-this.width)/2+'rem',
          fontSize: this.fontSize+'rem'
        }
        this.cssList.push(obj);
        this.opList.push({opacity:1});
        var level=Math.floor(this.num/2);
        var gap=((this.containerWidth-this.width)/2)/level;
        var firstLeft=(this.containerWidth-this.width)/2+this.width;
        var scalNow=this.scal;
        //右边位置
        for(let i=1; i<this.num/2;i++){
          level--;
          console.log(level)
          var w=this.width*scalNow;
          var h=this.height*scalNow;
          var lf=firstLeft+i*gap-w;
          var fs=this.fontSize*scalNow;
          var op=this.opacity*scalNow;
          var css={
            zIndex:level,
            width:w+'rem',
            height:h+'rem',
            left: (lf*6.2)+'rem',
            top:(this.height-h)/2+'rem',
            fontSize: fs+'rem',
          };

          this.cssList.push(css);
          this.opList.push({opacity:op/100});
          scalNow*=this.scal;
        }
        //左边位置
        for(let i=1;i<this.num/2;i++){
          scalNow/=this.scal;
          var w=this.width*scalNow;
          var h=this.height*scalNow;
          var lf=(i-1)*gap;
          var fs=this.fontSize*scalNow;
          var op=this.opacity*scalNow;
          var css={
            zIndex:level,
            width:w+'rem',
            height:h+'rem',
            left: 0.9*lf-w+'rem',
            top:(this.height-h)/2+'rem',
            fontSize: fs+'rem'
          }
          this.opList.push({opacity:op/100});
          this.cssList.push(css);
          level++;

        }
      },
      next() {
        this.index=(--this.index+this.num)%(this.num);
        var pre=this.cssList.shift();
        var pres = this.opList.shift();
        this.cssList.push(pre);
        this.opList.push(pres);
        this.synopsis=this.content[this.index].synopsis;
      },
      pre() {
        this.index=(++this.index)%(this.num);
        var next=this.cssList.pop();
        var nexts = this.opList.pop();
        this.cssList.unshift(next);
        this.opList.unshift(nexts);
        this.synopsis=this.content[this.index].synopsis;
      }
    }
  }
</script>

<style lang="scss">
  .down_line{
    border-bottom: 1px solid #3E3E3E;
  }
  .downLoadPop{
    .d_pop{
      a{
        display: block;
        color: #3E3E3E;
      }
      div{
        border-top: 3px solid #E0E0E0;
      }
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: white;
      line-height: 42px;
      font-size: 13px;
      font-weight: 500;
      color: #3E3E3E;
    }
    background-color: rgba(0,0,0,0.51);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .carousel-containner{
    width: 100%;
    height: calc(100% - 54px);
    .wrap{
      width: 100%;
      height: 100%;
      left: 0;
      margin-left: 0;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      .poster-item{
        transform: translateZ(0);
        width: 1.61rem;
        height: 100%;
        font-size: .16rem;
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        transition: all 0.5s;
        .bkImg{
          width: 100%;
          height: 90%;
          border-radius: 4px;
          overflow: hidden;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          img{
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
          }
        }
        .bkName{
          width: 100%;
          margin: 19px 0 12px 0;
          text-align: center;
          overflow: hidden;
          span{
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            font-size: 21px;
            font-weight: 800;
            color: #3E3E3E;
          }
        }
      }
    }
    .description{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      color: #3E3E3E;
      width: 8.16rem;
      margin: 0 auto;
    }
    .download_pic{
      display: block;
      width: 49px;
      right: 13px;
      bottom: 11px;
      img{
        width: 100%;
      }
    }
  }
</style>
